import "./style.scss";
import { Button } from "react-bootstrap";
import Image from "Assets/Images/Auth/girl-img1.svg";
import Image2 from "Assets/Images/Auth/girl-img3.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Field, Formik, Form } from "formik";
import { resetPasswordSchema } from "Utils/ValidationSchemas";
import { ResetPasswordAction } from "Redux/Auth/ResetPasswordReducer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { setLoader } from "../../../Redux/CommonReducer";
import { ErrorMessageComponent } from "../../../Components";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { isLoading } = useSelector((state) => state.ResetPasswordReducer);

  const initialValues = {
    email: searchParams.get("email"),
    password: "",
    password_confirmation: "",
    token: params?.token,
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    console.log("Submit", values);
    const res = await dispatch(ResetPasswordAction(values));
    setErrors(res.payload.errors);
    setSubmitting(false);
    if (!res.payload.error) {
      navigate("/", { replace: true });
      resetForm();
    }
  };

  return (
    <>
      <div className="auth-bg-img resetpassword-page">
        <div className="auth-page">
          <div className="d-flex justify-content-center align-items-end mvh-100">
            <div className="left-girl">
              <img src={Image} alt="img-1" />
            </div>
            <div className="authform">
              <div className="auth-form-box bg-signup rounded">
                <div className="box-header text-center">
                  <h2>{t("ResetPassword.Title")}</h2>
                  <p className="text-white fs-20">
                    {t("ResetPassword.Header")}
                  </p>
                </div>
                <div className="authbox-content">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={resetPasswordSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <label htmlFor="password">
                        {t("ResetPassword.Form.Password")}
                      </label>
                      <Field type="password" name={"password"} />
                      <ErrorMessageComponent name={"password"} />

                      <label htmlFor="password_confirmation">
                        {t("ResetPassword.Form.ConfirmNewPassword")}
                      </label>
                      <Field type="password" name={"password_confirmation"} />
                      <ErrorMessageComponent name={"password_confirmation"} />

                      <div>
                        <Button variant="primary" type="submit">
                          {t("Buttons.Update")}
                        </Button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            <div className="right-girl">
              <img src={Image2} alt="img-3" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
