import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "Middlewares/ApiClient";
import { apiCallTypes } from "Constants";
import { SnackBar } from "Components/Common";

const initialState = {
  isLoading: false,
  backgroundImage: {},
  infoPages: [],
};

export const QuestionAction = createAsyncThunk(
  "Question/QuestionAction",
  async ({ level, question, answer }, thunkApi) => {
    const res = await apiCall(
      `/my/levels/${level}/questions/${question}/answers/${answer}/attempt`,
      apiCallTypes.put
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const QuestionInfoAction = createAsyncThunk(
  "Question/QuestionInfoAction",
  async ({ level, question }, thunkApi) => {
    const res = await apiCall(
      `/my/levels/${level}/questions/${question}/info-pages`,
      apiCallTypes.get
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const QuestionSlice = createSlice({
  name: "QuestionReducer",
  initialState,
  extraReducers: {
    [QuestionAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      SnackBar("success", payload?.data?.message);
    },
    [QuestionAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [QuestionAction.pending]: (state) => {
      state.isLoading = true;
    },
    [QuestionInfoAction.fulfilled]: (state, { payload }) => {
      state.backgroundImage = payload?.data?.background_image;
      state.infoPages = payload?.data?.info_pages;
      state.isLoading = false;
    },
    [QuestionInfoAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [QuestionInfoAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default QuestionSlice.reducer;
