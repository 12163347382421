import Lottie from "react-lottie";
import * as animationData from "Assets/Images/json/PhoneRotate.json";
import "./style.scss";
import { useTranslation } from "react-i18next";

const RotatePhone = () => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  return (
    <>
      <div className="rotate-wrapper">
        <div className="animation">
          <Lottie
            options={defaultOptions}
            // height={400}
            // width={400}
            // isStopped={this.state.isStopped}
            // isPaused={this.state.isPaused}
          />
        </div>
        <div className="rotate-text">{t("RotatePhone.Header")}</div>
      </div>
    </>
  );
};

export default RotatePhone;
