import { Modal, ProgressBar } from "react-bootstrap";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPercentage } from "Utils";

const BadgesModal = (props) => {
  const { t } = useTranslation();
  const { badges } = useSelector((state) => state.ProfileReducer);

  return (
    <>
      <div className="badges-modal-wrapper">
        <Modal
          {...props}
          size="lg"
          className="badges-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="text-center" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Badges.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="main-badge">
              <div className="badge-name">
                {badges?.profile?.current_badge?.name}
              </div>
              <img
                src={badges?.profile?.current_badge?.icon?.url}
                alt="badge"
              />
              <div className="current-score">
                <ProgressBar
                  now={getPercentage(
                    badges?.profile?.total_score,
                    badges?.profile?.current_badge?.ending_score
                  )}
                />
                <div className="score">
                  <span>{badges?.profile?.total_score}</span>/
                  <span>{badges?.profile?.current_badge?.ending_score}</span>
                </div>
              </div>
            </div>
            <div className="divider-dual"></div>
            <div className="badges-grid">
              {badges?.all_badges?.map((badge) => (
                <div className="badge-item active" key={badge?.id}>
                  <img src={badge?.icon?.url} alt="badge" />
                  <div className="semibold">{badge?.name}</div>
                  <div className="light">
                    {badge?.starting_score}-{badge?.ending_score}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default BadgesModal;
