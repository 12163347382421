import { useEffect } from "react";
import "./style.scss";
import { Footer } from "Components";
import { Carousel } from "react-bootstrap";
import { Container, Row, Col, Button } from "react-bootstrap";
import PlayBtn from "Assets/Images/categories/play.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LevelAction } from "Redux/LevelReducer";
import ReactPlayer from "react-player/lazy";
import { setLoader } from "../../Redux/CommonReducer";

const Introduction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { level, isLoading } = useSelector((state) => state.LevelReducer);

  useEffect(() => {
    dispatch(LevelAction(params?.id));
  }, [dispatch, params?.id]);
  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  return (
    <>
      <div
        className="introduction-page"
        style={{
          ...(level?.background_image?.url && {
            backgroundImage: `url(${level?.background_image?.url})`,
          }),
        }}
      >
        <header>
          <Container fluid>
            <h1>{t("Introduction.Title")}</h1>
          </Container>
        </header>
        <div className="content">
          <Row className="w-100 justify-content-center">
            <Col lg="12">
              <div className="sliderbox">
                <Carousel controls={false} interval={null}>
                  {level?.urls?.map((item) => (
                    <Carousel.Item key={item}>
                      <div className="video-box">
                        <ReactPlayer
                          url={item}
                          playIcon={
                            <img
                              className="playbutton"
                              src={PlayBtn}
                              alt="Play"
                            />
                          }
                          light={true}
                          controls={true}
                        />
                      </div>
                    </Carousel.Item>
                  ))}
                  {level?.content_images?.map((item) => (
                    <Carousel.Item key={item?.id}>
                      <div className="video-box">
                        <img
                          className="cover"
                          src={item?.url}
                          alt="video-cover"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <p dangerouslySetInnerHTML={{ __html: level?.intro_page }}></p>
            </Col>
          </Row>
        </div>
        <footer>
          <div className="footer">
            <Row>
              <Col sm="12">
                <div className="text-center play-button">
                  <Button
                    className="play"
                    onClick={() =>
                      navigate(
                        `/app/questions/${level?.unattempted_que_id}?levelId=${params?.id}`,
                        {
                          replace: true,
                        }
                      )
                    }
                  >
                    {t("Buttons.Start")}
                  </Button>
                </div>
              </Col>
            </Row>
            <Footer />
          </div>
        </footer>
      </div>
    </>
  );
};

export default Introduction;
