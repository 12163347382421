import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "Middlewares/ApiClient";
import { apiCallTypes } from "Constants";

const initialState = {
  isLoading: false,
  level: null,
  result: null,
  questions: [],
  nextLevel: null,
};

export const LevelAction = createAsyncThunk(
  "Level/LevelAction",
  async (payload, thunkApi) => {
    const res = await apiCall(`/my/levels/${payload}`, apiCallTypes.get);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const ResultAction = createAsyncThunk(
  "Level/ResultAction",
  async (payload, thunkApi) => {
    const res = await apiCall(`/my/levels/${payload}/result`, apiCallTypes.get);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const LevelSlice = createSlice({
  name: "LevelReducer",
  initialState,
  extraReducers: {
    [LevelAction.fulfilled]: (state, { payload }) => {
      state.level = payload?.data?.data;
      state.isLoading = false;
    },
    [LevelAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [LevelAction.pending]: (state) => {
      state.isLoading = true;
    },
    [ResultAction.fulfilled]: (state, { payload }) => {
      [state.questions, state.result, state.nextLevel] = [
        payload?.data?.questions,
        payload?.data?.result,
        payload?.data?.next_level,
      ];
      state.isLoading = false;
    },
    [ResultAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [ResultAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default LevelSlice.reducer;
