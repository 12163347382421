import { useEffect, useMemo, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./style.scss";
import HomeIcon from "Assets/Images/new-assets/home.png";
import CrossIcon from "Assets/Images/new-assets/Cross-lg.png";
import { Footer, TableBaseComponent, PaginationComponent } from "Components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRank } from "Utils";
import { LeaderboardAction } from "Redux/LeaderboardReducer";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "Redux/CommonReducer";
import { ranksPerPage } from "Constants";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { isLoading, ranks, filterData } = useSelector(
    (state) => state.LeaderboardReducer
  );
  const [filters, setFilters] = useState({
    organization: "all",
    department: "all",
    team: "all",
  });

  useEffect(() => {
    dispatch(
      LeaderboardAction({ page: page, per_page: ranksPerPage, ...filters })
    );
  }, [dispatch, filters, page]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
      ...(e.target.name === "organization" && {
        department: "all",
        team: "all",
      }),
      ...(e.target.name === "department" && { team: "all" }),
    });
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: t("Leaderboard.Rank"),
        accessorKey: "rank",
        cell: ({ row: { index } }) => getRank(index, page, ranksPerPage, true),
      },
      {
        header: t("Leaderboard.User"),
        accessorKey: "user_name",
      },
      {
        header: t("Leaderboard.Team"),
        accessorKey: "team.name",
      },
      {
        header: t("Leaderboard.Department"),
        accessorKey: "department.name",
      },
      {
        header: t("Leaderboard.Organization"),
        accessorKey: "organization.name",
      },
      {
        header: t("Leaderboard.ScoreBadge"),
        accessorKey: "profile",
        cell: ({ getValue }) => (
          <div>
            {getValue()?.total_score}{" "}
            <img src={getValue()?.current_badge?.icon?.url} alt="Score" />
          </div>
        ),
      },
    ],
    [page]
  );

  return (
    <>
      <div className="leaderboard-page">
        <div className="header">
          <img
            src={HomeIcon}
            alt="home-icon"
            onClick={() => navigate("/app/home")}
          />
          <div className="title">
            <h2>{t("Leaderboard.Title")}</h2>
          </div>
          <img
            src={CrossIcon}
            alt="cross"
            onClick={() => navigate("/app/home")}
          />
        </div>
        <div className="page-body">
          <Form>
            {/*It is filters section*/}
            <Row className="form-row justify-content-center text-center">
              <Form.Group as={Col} sm="3">
                <Form.Label>{t("Leaderboard.Organization")}</Form.Label>
                <Form.Select
                  size="lg"
                  name="organization"
                  onChange={handleChange}
                  value={filters?.organization}
                >
                  <option value={"all"}>{t("Leaderboard.All")}</option>
                  {filterData?.organizations?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} sm="3">
                <Form.Label>{t("Leaderboard.Department")}</Form.Label>
                <Form.Select
                  size="lg"
                  name="department"
                  onChange={handleChange}
                  value={filters?.department}
                >
                  <option value={"all"}>{t("Leaderboard.All")}</option>
                  {filterData?.departments?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} sm="3">
                <Form.Label>{t("Leaderboard.Team")}</Form.Label>
                <Form.Select
                  size="lg"
                  name="team"
                  onChange={handleChange}
                  value={filters?.team}
                >
                  <option value={"all"}>{t("Leaderboard.All")}</option>
                  {filterData?.teams?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>

          <TableBaseComponent columns={columns} data={ranks?.data} />
        </div>
        <div className="footer">
          <PaginationComponent
            itemsCount={ranks?.paginate_info?.total || 1}
            itemsPerPage={ranksPerPage}
            currentPage={page}
            setCurrentPage={setPage}
            alwaysShown={true}
          />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
