import FooterLogo from "Assets/Images/Home/logo.svg";
import "./style.scss";

const Footer = () => {
  return (
    <>
      <div className="bottom-footer">
        <div className="footer-logo text-end">
          <img src={FooterLogo} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
