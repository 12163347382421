import { ProgressBar, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getRank } from "Utils";

const LevelsComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { levels, isLoading } = useSelector((state) => state.CategoryReducer);

  const navigation = (level) => {
    if (level?.progress_percentage >= 100)
      navigate(`/app/result/${level?.id}`, { state: { from: "categories" } });
    else if (level?.progress_percentage > 0 && level?.progress_percentage < 100)
      navigate(
        `/app/questions/${level?.unattempted_que_id}?levelId=${level?.id}`
      );
    else navigate(`/app/introduction/${level?.id}`);
  };

  return isLoading ? (
    <div className="level-spinner">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <div className="level-boxes">
      {levels?.data?.map((level, index) => (
        <div
          key={level?.id}
          className="level"
          onClick={() => navigation(level)}
        >
          <div className="level-text">{t("Categories.Level")}</div>
          <h1>
            {getRank(
              index,
              levels?.meta?.current_page,
              levels?.meta?.per_page,
              false
            )}
          </h1>
          <ProgressBar now={level?.progress_percentage} />
        </div>
      ))}
    </div>
  );
};

export default LevelsComponent;
