import { useEffect, useState } from "react";
import { Container, Row, Col, ListGroup, Spinner } from "react-bootstrap";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomeIcon from "Assets/Images/new-assets/home.png";
import { Footer } from "Components";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { CategoryAction, LevelsAction } from "Redux/CategoryReducer";
import { setLoader } from "Redux/CommonReducer";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { PaginationComponent } from "Components";
import { levelsPerPage } from "Constants";

const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  const { categories, levels } = useSelector((state) => state.CategoryReducer);

  useEffect(() => {
    fetchData(1);
  }, []);

  useEffect(() => {
    params?.id &&
      dispatch(
        LevelsAction({ id: params?.id, page: page, perPage: levelsPerPage })
      );
  }, [dispatch, params?.id, page]);

  const resetPage = () => {
    setPage(1);
  };

  const fetchData = async (page = categories?.meta?.current_page + 1) => {
    if (page !== 1 && categories?.data?.length >= categories?.meta?.total) {
      setHasMore(false);
      return;
    }
    page === 1 && dispatch(setLoader(true));
    const res = await dispatch(CategoryAction(page));
    page === 1 &&
      res?.payload?.data?.data[0]?.id &&
      !params?.id &&
      navigate(`${res?.payload?.data?.data[0]?.id}`, { replace: true });
    dispatch(setLoader(false));
  };

  return (
    <div className="categories-page">
      <header>
        <Container fluid className="p-0">
          <img src={HomeIcon} alt="" onClick={() => navigate("/app/home")} />
        </Container>
      </header>
      <div className="content">
        <Container className="p-0" fluid>
          <Row>
            <Col md="12" className="p-0">
              {categories?.data?.length > 0 ? (
                <div className="categories-content">
                  <div className="sidebar">
                    <div className="title">
                      <h1>{t("Categories.Title")}</h1>
                    </div>
                    <ListGroup id={"scrollable"}>
                      <InfiniteScroll
                        next={fetchData}
                        hasMore={hasMore}
                        loader={
                          <Spinner animation="border" variant="primary" />
                        }
                        scrollableTarget={"scrollable"}
                        dataLength={categories?.data.length}
                      >
                        {categories?.data.map((item) => (
                          <ListGroup.Item key={item?.id}>
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active disable" : undefined
                              }
                              to={`${item?.id}`}
                              onClick={resetPage}
                            >
                              {item?.name}
                            </NavLink>
                          </ListGroup.Item>
                        ))}
                      </InfiniteScroll>
                    </ListGroup>
                  </div>
                  <Outlet />
                </div>
              ) : (
                <h1 className="no-data">{t("Common.NoDataFound")}</h1>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <footer>
        <div className="footer">
          <PaginationComponent
            itemsCount={levels?.meta?.total}
            itemsPerPage={levelsPerPage}
            currentPage={page}
            setCurrentPage={setPage}
            alwaysShown={true}
          />
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default Categories;
