import { ErrorMessage } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

const ErrorMessageComponent = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id={`tooltip-bottom`}>{msg}</Tooltip>}
      >
        <div className="validation-message">{msg}</div>
      </OverlayTrigger>
    )}
  </ErrorMessage>
);

ErrorMessageComponent.propTypes = {
  name: PropTypes.string,
};


export default ErrorMessageComponent;


